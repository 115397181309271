var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "bg fill-height ma-0 pa-0" },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-card",
            { attrs: { elevation: "17" } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "title" }, [
                  _vm._v("Autorizar Serviço")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "left" } },
                        [
                          _c("span", { staticClass: "subtitle-1" }, [
                            _vm._v(" Você deseja autorizar "),
                            _c("b", [_vm._v("%CLIENT_NAME%")]),
                            _vm._v(" a ter acesso as seguintes permissões: ")
                          ])
                        ]
                      ),
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            _vm._l(_vm.authorizeScopeList, function(scope) {
                              return _c(
                                "v-list-item",
                                { key: scope.title },
                                [
                                  _c("v-icon", [_vm._v(_vm._s(scope.icon))]),
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(scope.title))
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(scope.description))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("div", { staticClass: "flex-fill" }),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: "primary",
                        loading: _vm.isAuthorizeLoading
                      },
                      on: { click: _vm.authorizeScopes }
                    },
                    [_vm._v(" Autorizar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("authorize-scope-form", {
        ref: "authorizeScopeForm",
        attrs: { "authorize-post-url": _vm.authorizePostUrl }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }