<template>
  <v-container
    class="bg fill-height ma-0 pa-0"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-card
        elevation="17"
      >
        <v-card-title>
          <span class="title">Autorizar Serviço</span>
        </v-card-title>
        <v-card-text>
          <v-container
            class="ma-0 pa-2"
          >
            <v-row
              align="center"
              justify="left"
            >
              <span class="subtitle-1">
                Você deseja autorizar <b>%CLIENT_NAME%</b> a ter acesso as seguintes permissões:
              </span>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-list
                two-line
              >
                <v-list-item
                  v-for="scope in authorizeScopeList"
                  :key="scope.title"
                >
                  <v-icon>{{scope.icon}}</v-icon>
                  <v-list-item-title>{{scope.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{scope.description}}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-fill"></div>
          <v-btn
            block
            color="primary"
            @click="authorizeScopes"
            :loading="isAuthorizeLoading"
          >
            Autorizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <authorize-scope-form
      ref="authorizeScopeForm"
      :authorize-post-url="authorizePostUrl"
    />
  </v-container>
</template>

<script>
  import {mapGetters} from "vuex"
  import {GET_FULL_STATE, GET_SCOPE} from "../store/modules/oauth"
  import {strCapitalize} from "../helpers/utils"
  import AuthorizeScopeForm from "../components/AuthorizeScopeForm"

  export default {
    name: "AuthorizeScopes",
    components: {AuthorizeScopeForm},
    data: () => ({
      isAuthorizeLoading: false,
      formsInputs: [],
    }),
    methods: {
      authorizeScopes() {
        const formDataInput = []
        for (let [key, value] of Object.entries(this.oauthFullState)) {
          if (!value) {
            value = ""
          }
          formDataInput.push({
            name: key,
            value: value,
          })
        }

        this.$refs.authorizeScopeForm.setFormData(formDataInput)
        this.$nextTick(
          () => {
            this.$refs.authorizeScopeForm.submitForm()
          },
        )
      },
    },
    computed: {
      authorizeScopeList() {
        if (!this.oathScopes) {
          return []
        }

        return this.oathScopes.split(" ").map(
          val => ({
            title: strCapitalize(val),
            description: strCapitalize(val),
            icon: "account_box",
          }),
        )
      },

      authorizePostUrl() {
        return this.$apiAuth.createUrl(this.$apiAuth.authorizePath)
      },

      ...mapGetters({
        oathScopes: GET_SCOPE,
        oauthFullState: GET_FULL_STATE,
      }),
    },
  }
</script>

<style scoped lang="scss">

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

</style>
