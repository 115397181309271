<template>
  <form
    ref="authorizeScopeForm"
    method="POST"
    name="authorize"
    :action=authorizePostUrl
  >
    <input
      v-for="formInput in formData"
      :key="formInput.name"
      type="hidden"
      :id=formInput.name
      :name="formInput.name"
      :value="formInput.value"
    />
  </form>
</template>

<script>
  export default {
    name: "AuthorizeScopeForm",
    props: {
      authorizePostUrl: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      formData: [],
    }),
    methods: {
      async setFormData(formData) {
        this.formData = formData
        return this.formData
      },
      submitForm() {
        this.$refs.authorizeScopeForm.submit()
      },
    },
  }
</script>

<style scoped>

</style>
