var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "authorizeScopeForm",
      attrs: { method: "POST", name: "authorize", action: _vm.authorizePostUrl }
    },
    _vm._l(_vm.formData, function(formInput) {
      return _c("input", {
        key: formInput.name,
        attrs: { type: "hidden", id: formInput.name, name: formInput.name },
        domProps: { value: formInput.value }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }